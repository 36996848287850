<template>
  <!-- <validation-observer ref="editTemplateModelForm" #default="{ invalid }"> -->
  <b-form id="editTemplateModelForm" @submit.prevent="updateTemplateModel">
    <b-card>
      <b-container>
        <b-row class="justify-content-md-center">
          <b-col cols="12" md="2">
            <b-button
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="outline-warning"
              to="/configuration/template-models"
              v-b-tooltip.hover
              title="Go Back"
            >
              <feather-icon icon="ArrowLeftCircleIcon" size="20" />
            </b-button>
          </b-col>
          <b-col cols="12" md="10">
            <h1>Edit Campaign Model</h1>
          </b-col>
        </b-row>
        <hr />
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group label="Campaign Model Title: *" label-for="h-title">
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required|regex:^[a-zA-Z].*$"
              >
                <b-form-input
                  id="h-title"
                  v-model="title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Title"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col md="6" cols="12">
            <b-form-group
              label="Campaign Model Image : *"
              label-for="tempImage"
            >
              <b-img thumbnail fluid :src="path + this.imagePreview  + 'ss'" />
              <validation-provider
                #default="{ errors }"
                name="Image"
                rules="required"
              >
                <b-form-file
                  id="image"
                  @change="ImageInputChanged"
                  accept="image/*"
                  class="mt-1"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Click Here to Upload an Image"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col cols="12">
            <b-form-group label="Template:">
              <editor
                api-key="xdqfulgipfly0m22ltfz73tgqqi3vfoqf7nhgszcjvio4a1u"
                v-model="templateContent"
                :init="{
                  selector: '.editor',
                  plugins:
                    'fullpage code advlist link image preview lists wordcount',
                  bbcode_dialect: 'punbb',
                  height: 600,
                }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <!-- submit and reset -->
          <b-col>
            <!-- <b-col offset-md="4"> -->
            <b-button
              type="submit"
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="outline-success"
              class="mr-1"
            >
              <!-- :disabled="invalid" -->
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </b-form>
  <!-- </validation-observer> -->
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BCard,
  BImg,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  VBTooltip,
  BButton,
  BContainer,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import Ripple from "vue-ripple-directive";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BImg,
    BAvatar,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormFile,
    BForm,
    VBTooltip,
    BButton,
    vSelect,
    editor: Editor,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      title: null,
      imagePreview: null,
      imageToUpdate: null,
      templateContent: null,
      did: [],
      domains: [],
      data: {},
      // validation rules
      required,
      regex,
      url,
      path: this.$ImagesURL + "template/",
    };
  },
  computed: {
    templateModelID() {
      return this.$store.state.appsManager.templateModelID;
    },
    alltemplateModels() {
      return this.$store.state.appsManager.templateModels;
    },
  },
  created() {
    this.appendTemplateModel();
  },
  methods: {
    appendTemplateModel(id) {
      if (this.alltemplateModels.length > 0) {
        let items = this.alltemplateModels;

        let _templateModels = items.find((o) => o.id == this.templateModelID);

        this.title = _templateModels.title;
        this.imagePreview = _templateModels.image_preview;
        this.templateContent = _templateModels.content_body;
      } else {
        this.$store
          .dispatch("appsManager/getAllTemplateModels")
          .then(({ data }) => {
            let _templateModels = data["template-models"].find(
              (o) => o.id == this.templateModelID
            );

            this.title = _templateModels.title;
            this.imagePreview = _templateModels.image_preview;
            this.templateContent = _templateModels.content_body;
          })
          .catch(console.error);
      }
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    ImageInputChanged(e) {
      this.imageToUpdate = e.target.files[0];
    },
    async updateTemplateModel(id) {
      //check if the fields not all of them are empty
      if (!this.title && !this.imageToUpdate && this.templateContent) {
        this.showToast(
          "warning",
          "AlertOctagonIcon",
          "Alert",
          `You need to fill a field so as updating it.`
        );
        return;
      }
      let inputsData = new FormData();

      inputsData.append("id", this.templateModelID);
      inputsData.append("title", this.title);
      inputsData.append("image_preview", this.imageToUpdate);
      inputsData.append("content_body", this.templateContent);
      await axios
        .post("/update-template-model", inputsData)
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            router.push({ name: "configuration-template-models" });
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
  },
};
</script>
